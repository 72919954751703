import { Component, OnInit } from '@angular/core';
import { ScriptService } from './services/script.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  constructor(
    private scriptService: ScriptService,
  ){}

  ngOnInit(): void {
    this.scriptService.load('googleMaps')
      .then(data => { this.scriptService.isGoogleMapServiceLoaded = true;})
      .catch(error => {});
  }
}
