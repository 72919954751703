import { Injectable } from "@angular/core";

interface Coordinate {
    lat: number;
    lng: number;
}

@Injectable({
    providedIn: 'root'
})
export class MapsService {

    getCoordinatesFromGoogleMapsRoute(directionsServiceResult): Coordinate[] {
        let routeCoordinates: Coordinate[] = [];
        var route = directionsServiceResult.routes[0];
        for (var i = 0; i < route.legs.length; i++) {
            var leg = route.legs[i];
            for (var j = 0; j < leg.steps.length; j++) {
                var stepp = leg.steps[j];
                // Extracting the coordinate points from each step's path
                var path = stepp.path;
                for (var k = 0; k < path.length; k++) {
                    var latLng = path[k];
                    var lat = latLng.lat();
                    var lng = latLng.lng();
                    routeCoordinates.push({ lat: lat, lng: lng });
                }
            }
        }
        return routeCoordinates;
    }

    calculateRotationAngle(initialAngle: number, prevX: number, prevY: number, currentX: number, currentY: number) : number {
        // Calculate the change in x and y coordinates
        const deltaX = currentX - prevX;
        const deltaY = currentY - prevY;
        // Calculate the angle in radians
        const angleRadians = Math.atan2(deltaY, deltaX);
        // Calculate the angle in degrees
        let angleDegrees = angleRadians * (180 / Math.PI);
        // Adjust the angle to be between 0 and 360 degrees
        if (angleDegrees < 0) {
            angleDegrees += 360;
        }
        // Calculate the rotation angle based on the initial angle
        let rotationAngle:number = angleDegrees - initialAngle;
        // Adjust the rotation angle to be between -180 and 180 degrees
        if (rotationAngle <= -180) {
            rotationAngle += 360;
        } else if (rotationAngle > 180) {
            rotationAngle -= 360;
        }
        return rotationAngle;
    }

}