import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ScriptService } from '../services/script.service';
import { LocalTripMapService } from './local-trip-map.service';
declare var google;

@Component({
  selector: 'app-local-trip-map',
  templateUrl: './local-trip-map.component.html',
  styleUrls: ['./local-trip-map.component.scss']
})
export class LocalTripMapComponent implements OnInit {

  map: any;
  accessCode:any;
  dealerTripData:any;

  constructor(
    private ltService: LocalTripMapService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private scriptService: ScriptService,
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.accessCode = params['id'];
    });
  }

  ngOnInit(): void {
    this.getInitData();
  }
  
  async getInitData(){
    let warehousePayload = await this.getWarehouseData();
    if(!warehousePayload){
      this.router.navigate(['']);
    }
    this.dealerTripData = await this.getDealerTripData(warehousePayload);
    if(!this.dealerTripData){
      this.router.navigate(['']);
    }
    this.checkGoogleScriptLoadingState();
  }

  getWarehouseData(){
    return new Promise( resolve => {
      this.ltService.getWarehouseId(this.accessCode)
      .pipe(
        catchError((error: any) => {
          resolve(false);
          return throwError(error);
        })
      )
      .subscribe(resp => {
        resolve(resp);
      });
    });
  }

  getDealerTripData(warehousePayload){
    return new Promise( resolve => {
      this.ltService.getDealerTripDetails(warehousePayload)
      .pipe(
        catchError((error: any) => {
          resolve(false);
          return throwError(error);
        })
      )
      .subscribe(resp => {
        resolve(resp);
      });
    });
  }

  checkGoogleScriptLoadingState(){  
    if(this.scriptService.isGoogleMapServiceLoaded){
      this.renderMap();
    }else{
      setTimeout(() => {
        this.checkGoogleScriptLoadingState()
      }, 100);
    }
  }

  getTimeDDMMMhhmm(timeStamp){
    let date = new Date(timeStamp);
    let longData = moment(date).format('DD-MMM-YYYY, HH:mm');
    return longData;
  }

  renderMap(){
    let globalVar = this;
    let originCoordinates = { lat: this.dealerTripData['originLatitude'], lng: this.dealerTripData['originLongitude'] };
    let dealerCoordinates = { lat: this.dealerTripData['destinationLatitude'], lng: this.dealerTripData['destinationLongitude'] };
    let vehicleCoordinates = { lat: this.dealerTripData['lastLocation']['latitude'], lng: this.dealerTripData['lastLocation']['longitude'] };
    let originName = this.dealerTripData['originWarehouseName'];
    let dealerName = this.dealerTripData['destinationWarehouseName'];
    let vehicleNumber = this.dealerTripData['vehicleNumber'];
    let angle = this.dealerTripData['lastLocation']['angle'] ? this.dealerTripData['lastLocation']['angle'] : 0;
    let address = this.dealerTripData['lastLocation']['address'];

    this.map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        center: vehicleCoordinates,
        zoom: 12,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        clickableIcons: false
      }
    );

    new google.maps.Marker({ // origin marker
      position: originCoordinates,
      icon: {
        url: 'assets/images/warehouse.png',
        scaledSize: new google.maps.Size(24, 24)
      },
      map: this.map,
    });

    new google.maps.Marker({ // origin name
      position: originCoordinates,
      label: {
        text: originName,
        className: 'gmap-marker-label',
      },
      icon: {
        url: 'assets/images/gmaps_blank.svg',
        scaledSize: new google.maps.Size(26, 26)
      },
      map: this.map,
    });

    new google.maps.Marker({ // vehicle pulse
      position: vehicleCoordinates,
      label: {
        text: ' ',
        className: 'gmap-marker-pulse',
        anchor: new google.maps.Point(5, 5),
      },
      icon: {
        url: 'assets/images/gmaps_blank.svg',
        scaledSize: new google.maps.Size(10, 10)
      },
      zIndex: 4,
      map: this.map
    });

    let vehicleMarker = new google.maps.Marker({ // vehicle marker
      title: 'vehicleMarkerLayer',
      position: vehicleCoordinates,
      icon: {
        url: 'assets/images/gmaps_blank.svg',
        scaledSize: new google.maps.Size(15, 44),
        anchor: new google.maps.Point(7.5, 25),
      },
      label: {
        text: vehicleNumber,
        className: 'gmap-vehicle-label',
        anchor: new google.maps.Point(5, 5),
      },
      zIndex: 5,
      map: this.map,
    });

    let dealerMarker = new google.maps.Marker({ // dealer marker
      position: dealerCoordinates,
      map: this.map,
      zIndex: 1000,
    });

    new google.maps.Marker({ // dealer name
      position: dealerCoordinates,
      label: {
        text: dealerName,
        className: 'gmap-marker-label',
      },
      icon: {
        url: 'assets/images/gmaps_blank.svg',
        scaledSize: new google.maps.Size(26, 26)
      },
      map: this.map,
    });

    let driverName = this.dealerTripData['driverName'];
    let driverNumber = this.dealerTripData['driverMobileNumber'];
    let contentString = `<div style="color: #3e3e3e; margin-bottom: 5px;">Driver Name: <span style="font-family: lato-bold;color: #3e3e3e;">${driverName}</span></div>`
    + `<div style="color: #3e3e3e; margin-bottom: 5px;">Driver Number: <span style="font-family: lato-bold;color: #3e3e3e;">${driverNumber}</span></div>`
    + `<div style="color: #3e3e3e;">Last Location : ${address}</div>`;
    let vehicleInfowindow = new google.maps.InfoWindow({
      content: contentString,
    });
    vehicleMarker.addListener('click', function () {
      vehicleInfowindow.open({
        anchor: vehicleMarker,
        map: globalVar.map,
        shouldFocus: true,
      });
    });
    vehicleMarker.addListener('mouseover', function () {
      vehicleInfowindow.open({
        anchor: vehicleMarker,
        map: globalVar.map,
        shouldFocus: true,
      });
    });
    vehicleMarker.addListener('mouseout', function () {
      vehicleInfowindow.close({
        anchor: vehicleMarker,
        map: globalVar.map,
        shouldFocus: true,
      });
    });

    let dealerEta = this.getTimeDDMMMhhmm(this.dealerTripData['dealerEta']);
    let etaContentString = `<div style="color: #3e3e3e;"> ETA : ${dealerEta}</div>`;
    let etaInfowindow = new google.maps.InfoWindow({
      content: etaContentString,
    });
    dealerMarker.addListener('click', function () {
      etaInfowindow.open({
        anchor: dealerMarker,
        map: globalVar.map,
        shouldFocus: true,
      });
    });
    dealerMarker.addListener('mouseover', function () {
      etaInfowindow.open({
        anchor: dealerMarker,
        map: globalVar.map,
        shouldFocus: true,
      });
    });
    dealerMarker.addListener('mouseout', function () {
      etaInfowindow.close({
        anchor: dealerMarker,
        map: globalVar.map,
        shouldFocus: true,
      });
    });

    google.maps.event.addListener(this.map, 'idle', function () {
      setTimeout(function () {
        let marekerTitle = 'vehicleMarkerLayer';
        let node: any = document.querySelector(`[title="${marekerTitle}"]`);
        if (node) {
          node.style.transform = 'rotate(' + angle + 'deg)';
          node.style.backgroundImage = "url('assets/images/gmaps_truck.svg')";
          node.style.backgroundSize = "contain";
          node.style.backgroundRepeat = "no-repeat";
          node.style.backgroundPosition = "center";
        }
      }, 200);
    });

  }

}
