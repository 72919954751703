import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/operators';
import { UpcountryDealerTrackingService } from './upcountry-dealer-tracking.service';

@Component({
  selector: 'app-upcountry-dealer-tracking-view',
  templateUrl: './upcountry-dealer-tracking-view.component.html',
  styleUrls: ['./upcountry-dealer-tracking-view.component.scss'],
})
export class UpcountryDealerTrackingViewComponent implements OnInit {
  accessCode: any;
  tripDetails: any;
  tollDetails: any = [];
  reversedArray:any = []
  picDetails: any;
  showChartView:boolean = true;
  showTollLocations:boolean = false;
  lastKnownLocation:any;
  showLoader:boolean = false;
  tripCreation :any;
  tripInTransit :any;
  tripCompletion :any;
  animationLayerObject = {
    isAnimationApplicable: false,
    isAnimationPlayMode: false,
    routeCoordinates: [],
    lastCoordinateIndex: 0,
    vehicleMarker: undefined,
    animationSpeedStepInMilliseconds: 50,
    resetMapsCenter: true,
    zoomChangedListener: undefined,
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private upcService: UpcountryDealerTrackingService
  ) {
    let urlPath = window.location.pathname;
    this.accessCode = urlPath.split('/')[2];
  }

  ngOnInit(): void {
    this.getTripDetailsViaAccessCode();
  }

  getTripDetailsViaAccessCode() {
    this.showLoader = true;
    this.upcService
      .getTripDetailsViaAccessCode(this.accessCode)
      .pipe(
        catchError((error: any) => {
          return throwError(error);
        })
      )
      .subscribe((tripId) => {
        this.upcService
          .getTripDetails(tripId.tripId)
          .pipe(
            catchError((error: any) => {
              this.showLoader = false;
              return throwError(error);
            })
          )
          .subscribe((trip) => {
            this.showLoader = false;
            this.tripDetails = trip;

            for (const item of this.tripDetails.stateChangeLogs) {
              if (item.newState === "CREATED") {
                this.tripCreation= item;
              } else if (item.newState === "ACTIVE" ) {
                this.tripInTransit = item;
              }
              else if (item.newState === "COMPLETED") {
                this.tripCompletion = item;
              }
            }
            this.tripDetails.originWarehouse = {
              "latitude": trip.originWarehouse.latitude,
              "longitude": trip.originWarehouse.longitude,
              "originWarehouseName" :trip.originWarehouseName
            }
            this.tripDetails.dropPoints.forEach((element) => {
              if (element.picContractNumber && element.picContractNumber.picNumber && element.picContractNumber.picNumber == 1) {
                this.picDetails = element.picContractNumber.contactNumber;
              }
              this.tripDetails.destinationWarehouse = {
                "latitude": element.latitude,
                "longitude": element.longitude,
                "destinationWarehouseName":element.destinationWarehouseName
              }
              this.upcService
                .getTollPoints(this.tripDetails.trackingUid)
                .pipe(
                  catchError((error: any) => {
                    return throwError(error);
                  })
                )
                .subscribe((toll) => {
                  if(toll['transactions'] && toll['transactions'].length){
                    this.tollDetails = toll['transactions'];
                    this.lastKnownLocation = this.tollDetails[this.tollDetails.length -1];
                    this.reversedArray = this.reverseArray(this.tollDetails);
                  }
                });
            });
          });
      });
  }

  reverseArray(tollDetails){
    const reversedArr = tollDetails.slice().reverse();
    return reversedArr;
  }
  toggleView(){
    this.showChartView = !this.showChartView;
    if(!this.showChartView){
      this.fetchMapData(this.tripDetails)
    }
  }

  async fetchMapData(trip) {
    let createdOnDate = new Date(trip.createdOn);
    
    createdOnDate.getTime();
    let startDate =  createdOnDate.setDate(createdOnDate.getDate() - 3);
    let endDate =  createdOnDate.setDate(createdOnDate.getDate() + 4);
    let apiUrl = 'trip/'+trip.trackingUid+'/transactions?startTimeEpoch='+startDate+'&endTimeEpoch='+endDate;
   
  }

  showAllUpdates(){
    this.showTollLocations = !this.showTollLocations;
  }

}
