import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UpcountryDealerTrackingService {
  prodUrl = environment.apiBaseUrl;

  constructor(
    private http: HttpClient
  ) { }

  private handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  getTripDetailsViaAccessCode(accessCode) {
    let options = new HttpHeaders();
    return this.http
      .get<any>(`${this.prodUrl}/durin/api/v1/data/${accessCode}`, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }

  getTollPoints(trackingUid) {
    let options = new HttpHeaders().set('Authorization', environment.hopscotchAuthToken);
    return this.http
      .get<any>(`${environment.hopscotchBaseUrl}/hopscotch/api/v1/trip/${trackingUid}/transactions`, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }
  
  getTripDetails(tripId) {
    let options = new HttpHeaders();
    return this.http
      .get<any>(`${this.prodUrl}/ryoko/api/v1/public/trip-type/SECONDARY_UP_COUNTRY/trip/${tripId}`, {
        headers: options
      })
      .pipe(
        map(data => {
          let dataToCache = data;
          return dataToCache;
        }),
        catchError(this.handleError.bind(this))
      ) as Observable<any>;
  }

}
