import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { forkJoin, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { MapsService } from '../map-service.service';
declare var google;

interface RunningStatus {
  runningPingPoints: any;
  expandDetails: boolean;
  distanceFromOrigin: any;
  lastLocation: any;
  lastLocationTime: any;
  etaKm: any;
  etaTime: any;
  status: String;
  statusKey: String;
}
@Component({
  selector: 'app-trip-map',
  templateUrl: './trip-map.component.html',
  styleUrls: ['./trip-map.component.scss'],
})
export class TripMapComponent {
  otDirectionsRenderer: any;
  otDirectionsRendererBorder: any;
  directionsService: any;
  routeMarkers: any = [];
  monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  vehicleMarker: any;
  vehicleMarkers: any = [];
  vehiclePulses: any = [];
  runningStatus: RunningStatus;

  mapCenter = { lat: 20.5937, lng: 78.9629 };
  mapCenterZoom = 5;
  map: any;

  showLoader: boolean = true;
  @Input() trip: any;
  @Input() tollDetails:any;

  animationLayerObject = {
    isAnimationApplicable: false,
    isAnimationPlayMode: false,
    routeCoordinates: [],
    lastCoordinateIndex: 0,
    vehicleMarker: undefined,
    animationSpeedStepInMilliseconds: 50,
    resetMapsCenter: true,
    zoomChangedListener: undefined,
  };
  @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef;
  constructor( private renderer: Renderer2,private mapService: MapsService,private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    if(this.tollDetails.length){
      setTimeout(() => {
        this.otDirectionsRenderer = new google.maps.DirectionsRenderer();
        this.otDirectionsRendererBorder = new google.maps.DirectionsRenderer();
        this.directionsService = new google.maps.DirectionsService();
        this.renderMap(this.trip);
      }, 2000);
    }
  }

  async renderMap(trip) {
    this.resetAnimationLayerObject();
    
    let originDetails = trip.originWarehouse;
    let destinationDetails = trip.destinationWarehouse;
    let originCoordinates = { lat: originDetails.latitude, lng: originDetails.longitude };
    let destinationCoordinates = { lat: destinationDetails.latitude, lng: destinationDetails.longitude };
    const originLatLngFormat = new google.maps.LatLng(originCoordinates['lat'], originCoordinates['lng']);
    const destinationLatLngFormat = new google.maps.LatLng(destinationCoordinates['lat'], destinationCoordinates['lng']);

    this.map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: 12,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        clickableIcons: false
      }
    );
    let map = this.map;

    let originMarker = new google.maps.Marker({
      position: originCoordinates,
      label: {
        text: originDetails['originWarehouseName'],
        className: 'gmap-marker-label',
      },
      icon: {
        url: 'assets/images/gmaps_origin.svg',
        scaledSize: new google.maps.Size(12, 12)
      },
      map: map,
    });

    if(destinationCoordinates.lat && destinationCoordinates.lng){
      let destinationMarker = new google.maps.Marker({
        position: destinationCoordinates,
        label: {
          text: destinationDetails['destinationWarehouseName'],
          className: 'gmap-marker-label',
        },
        icon: {
          url: 'assets/images/gmaps_destination.svg',
          scaledSize: new google.maps.Size(26, 26)
        },
        map: map,
      });
    }

    let tripLocationPoints;
    let lastLocation;
    let vehicleLatLngFormat;
   
        tripLocationPoints = this.tollDetails;
        if(tripLocationPoints){
          tripLocationPoints = tripLocationPoints.map(toll => {
            toll['latitude'] = toll['tollPlazaLatitude'];
            toll['longitude'] = toll['tollPlazaLongitude'];
            return toll;
          });
       
    }
      lastLocation = tripLocationPoints[tripLocationPoints.length - 1];
      vehicleLatLngFormat = new google.maps.LatLng(lastLocation.latitude, lastLocation.longitude);
    
    this.addFastagLocationsLayer(map, tripLocationPoints, originLatLngFormat, destinationLatLngFormat, vehicleLatLngFormat,destinationCoordinates);  
   
  }

  async addFastagLocationsLayer(map, tripLocationPoints, originLatLngFormat, destinationLatLngFormat, vehicleLatLngFormat,destinationCoordinates){
    let waypts = [];
    let wayPointsLength = tripLocationPoints.length;
    if (wayPointsLength > 0) {
      let step = Math.ceil(wayPointsLength / 5);
      for (let i = 0; i < wayPointsLength; i = i + step) {
        const vehicleLatLngFormat = new google.maps.LatLng(tripLocationPoints[i].latitude, tripLocationPoints[i].longitude);
        waypts.push({
          location: vehicleLatLngFormat,
          stopover: false
        });
      }
      waypts.push({
        location: vehicleLatLngFormat,
        stopover: false
      });
    }

    if(vehicleLatLngFormat && vehicleLatLngFormat.lat()){
      let requestOt: any = {
        origin: originLatLngFormat,
        destination: vehicleLatLngFormat,
        waypoints: waypts,
        optimizeWaypoints: false,
        travelMode: 'DRIVING',
      };
  
      let directionsService = new google.maps.DirectionsService();
      let otDirectionsRenderer = new google.maps.DirectionsRenderer();
      let otDirectionsRendererBorder = new google.maps.DirectionsRenderer();
      otDirectionsRenderer.setMap(map);
      otDirectionsRenderer.setOptions({
        polylineOptions: { strokeColor: "#2AC26B", strokeWeight: 2, strokeOpacity: 1 },
        suppressMarkers: true
      });
      otDirectionsRendererBorder.setMap(map);
      otDirectionsRendererBorder.setOptions({
        polylineOptions: { strokeColor: "#0E823F", strokeWeight: 4, strokeOpacity: 1 },
        suppressMarkers: true
      });
  
      let gVar = this;
      map.setZoom(12);
  
      await directionsService.route(requestOt, function (result, status) {
        if (status == 'OK') {
          otDirectionsRendererBorder.setDirections(result);
          otDirectionsRenderer.setDirections(result);
          gVar.animationLayerObject.isAnimationApplicable = true;
          gVar.animationLayerObject.routeCoordinates = gVar.mapService.getCoordinatesFromGoogleMapsRoute(result);
        }
      });
    }


    if (tripLocationPoints) {
      for (let [tollIndex, toll] of tripLocationPoints.entries()) {
        const pingCoordinates = new google.maps.LatLng(toll.latitude, toll.longitude);
        let tollMarker: any = new google.maps.Marker({
          position: pingCoordinates,
          icon: {
            url: 'assets/images/gmap-toll-booth.svg',
            scaledSize: new google.maps.Size(48, 48),
            anchor: new google.maps.Point(0, 48),
          },
          draggable: false,
          optimized: false,
          zIndex: 4,
          map: map,
          visible: true
        });

        let tollNameMarker = new google.maps.Marker({
          position: pingCoordinates,
          label: {
            text: toll['tollPlazaName'],
            className: 'gmap-marker-label',
          },
          icon: {
            url: 'assets/images/gmaps_blank.svg',
            scaledSize: new google.maps.Size(28, 28)
          },
          map: map,
          optimized: false,
          visible: true
        });
        
        google.maps.event.addListener(map, 'zoom_changed', function() {
          const currentZoom = map.getZoom();
          if (currentZoom >= 10) {
            tollNameMarker.setVisible(true);
          } else {
            tollNameMarker.setVisible(false);
          }
        });

        let date = this.formatDateddmmyyyyHHMMAMPM(toll['readerReadTime'])
        let lineOne = `<p style="font-family: lato-bold; margin-bottom: 3px;">${toll['tollPlazaName']}</p>`;
        let lineTwo = `<p style="color: #6d6d6d;font-size: 12px;line-height: 15px;">${date}</p>`;
        let infowindow: any = new google.maps.InfoWindow({
          content: lineOne + lineTwo,
        });
        tollMarker.addListener('mouseover', function () {
          infowindow.open({
            anchor: tollMarker,
            map,
            shouldFocus: false,
          });
        });
        tollMarker.addListener('mouseout', function () {
          infowindow.close({
            anchor: tollMarker,
            map,
            shouldFocus: true,
          });
        });
      }
    }
   
    if (destinationCoordinates.lat && destinationCoordinates.lng) {
      
      setTimeout(() => {
        this.boungMapWithOriginAndDestination(map, originLatLngFormat, destinationLatLngFormat);
      }, 100);
     
    } else {
      // Code to execute if any of the properties are missing or have falsy values
      map.setCenter(originLatLngFormat);
    }
   
  }

  boungMapWithOriginAndDestination(map, originLatLngFormat, destinationLatLngFormat){
    // bound the map within origin and destination
    const bounds = new google.maps.LatLngBounds();
    bounds.extend(originLatLngFormat);
    bounds.extend(destinationLatLngFormat);
    map.fitBounds(bounds);
  }



  formatDateddmmyyyyHHMMAMPM(date) {
    let newDate = new Date(date);
    let day = newDate.getDate();
    let monthNumber = newDate.getMonth();
    let month = this.monthNames[monthNumber];
    let year = newDate.getFullYear();
    var hours = newDate.getHours();
    var minutes: any = newDate.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let convertedDate =
      ('0' + day).slice(-2) +
      ' ' +
      month +
      ' ' +
      year +
      ' - ' +
      hours +
      ':' +
      minutes +
      ' ' +
      ampm;
    return convertedDate;
  }




  resetAnimationLayerObject() {
    this.animationLayerObject = {
      isAnimationApplicable: false,
      isAnimationPlayMode: false,
      routeCoordinates: [],
      lastCoordinateIndex: 0,
      vehicleMarker: undefined,
      animationSpeedStepInMilliseconds: 50,
      resetMapsCenter: true,
      zoomChangedListener: undefined,
    };
  }

  startAnimateTruckIcon() {
    let gVar = this;
    this.animationLayerObject.isAnimationPlayMode = true;
    this.animationLayerObject.resetMapsCenter = true;
    if (this.animationLayerObject.zoomChangedListener) {
      google.maps.event.removeListener(
        this.animationLayerObject.zoomChangedListener
      );
    }
    let map = this.map;
    map.setZoom(12);

    const zoomListener = () => {
      gVar.animationLayerObject.resetMapsCenter = false;
    };
    this.animationLayerObject.zoomChangedListener = this.map.addListener(
      'zoom_changed',
      zoomListener
    );

    let routeCoordinates = this.animationLayerObject.routeCoordinates;
    if (!this.animationLayerObject.vehicleMarker) {
      this.animationLayerObject.vehicleMarker = new google.maps.Marker({
        title: 'vehicle_position',
        position:
          routeCoordinates[gVar.animationLayerObject.lastCoordinateIndex], // Set initial position
        map: map,
        icon: {
          url: 'assets/images/gmaps_truck.svg',
          scaledSize: new google.maps.Size(48, 48),
          anchor: new google.maps.Point(24, 24),
        },
      });
    }

    function rotateIcon(ang) {
      const truckImage = document.querySelector('img[src="assets/images/gmaps_truck.svg"]');
      if (truckImage) {
        truckImage.setAttribute('style', `transform: rotate(${ang}deg)`);
      }
    }

    // Set the animation parameters
    let initialAngle = 0;
    let currentPosition = gVar.animationLayerObject.lastCoordinateIndex;

    // Function to animate the marker along the coordinates
    function animateMarker() {
      currentPosition++;
      if (
        currentPosition > 0 &&
        currentPosition < routeCoordinates.length &&
        gVar.animationLayerObject.vehicleMarker
      ) {
        gVar.animationLayerObject.vehicleMarker.setPosition(
          routeCoordinates[currentPosition]
        );
        let oldLat: number = routeCoordinates[currentPosition - 1]['lat'];
        let oldLng: number = routeCoordinates[currentPosition - 1]['lng'];
        let newLat: number = routeCoordinates[currentPosition]['lat'];
        let newLng: number = routeCoordinates[currentPosition]['lng'];
        let angle: number = gVar.mapService.calculateRotationAngle(
          initialAngle,
          oldLat,
          oldLng,
          newLat,
          newLng
        );
        rotateIcon(angle);
      }

      gVar.animationLayerObject.lastCoordinateIndex = currentPosition;
      if (
        !gVar.animationLayerObject.isAnimationPlayMode ||
        currentPosition >= routeCoordinates.length
      ) {
        gVar.stopTruckAnimate();
        if (currentPosition >= routeCoordinates.length) {
          gVar.animationLayerObject.lastCoordinateIndex = 0;
        }
        return;
      } else {
        if (gVar.animationLayerObject.resetMapsCenter) {
          var pt = new google.maps.LatLng(
            routeCoordinates[currentPosition]['lat'],
            routeCoordinates[currentPosition]['lng']
          );
          map.setCenter(pt);
        } else {
          gVar.animationLayerObject.zoomChangedListener = undefined;
        }
        setTimeout(
          animateMarker,
          gVar.animationLayerObject.animationSpeedStepInMilliseconds
        );
      }
    }

    // Call the animateMarker function to start the animation
    animateMarker();
  }

  updateAnimationSpeed(speed: '1X' | '2X' | '4X' | '10X') {
    switch (speed) {
      case '1X':
        this.animationLayerObject.animationSpeedStepInMilliseconds = 50;
        break;
      case '2X':
        this.animationLayerObject.animationSpeedStepInMilliseconds = 25;
        break;
      case '4X':
        this.animationLayerObject.animationSpeedStepInMilliseconds = 12;
        break;
      case '10X':
        this.animationLayerObject.animationSpeedStepInMilliseconds = 5;
        break;
    }
  }

  stopTruckAnimate() {
    this.animationLayerObject.isAnimationPlayMode = false;
    this.animationLayerObject.resetMapsCenter = true;
  }


}
