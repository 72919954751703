<div class="center">
    <div class="content">
        <div>
            <img class="logo" src="/assets/images/ff-logo.svg" alt="">
        </div>
        <div class="msg">
            <div>
                404 <span> That's an Error</span>
                <br>The Requested URL was not found
                <br>on this server
            </div>
            <img class="error" src="/assets/images/not-found-ico.svg">
        </div>
    </div>
</div>