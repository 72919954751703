import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/internal/operators';
import { Observable, throwError } from 'rxjs'

@Injectable({
    providedIn: 'root'
})
export class PrimaryTripMapService {

    constructor(
        private http: HttpClient
    ) { }

    prodUrl = environment.apiBaseUrl;

    private handleError(error: HttpErrorResponse) {
        return throwError(error);
    }

    getTripVehicleRunningStatus(tripId) {
        let options = new HttpHeaders();
        return this.http
            .get<any>(`${this.prodUrl}/ryoko/api/v1/public/trip/${tripId}/running-status`, {
                headers: options
            })
            .pipe(
                map(data => {
                    let dataToCache = data;
                    return dataToCache;
                }),
                catchError(this.handleError.bind(this))
            ) as Observable<any>;
    }

    getTripVehicleWayPoints(tripId) {
        let options = new HttpHeaders();
        return this.http
            .get<any>(`${this.prodUrl}/ryoko/api/v1/public/trip/${tripId}/location-points`, {
                headers: options
            })
            .pipe(
                map(data => {
                    let dataToCache = data;
                    return dataToCache;
                }),
                catchError(this.handleError.bind(this))
            ) as Observable<any>;
    }

    getTripDetails(tripId) {
        let options = new HttpHeaders();
        return this.http
            .get<any>(`${this.prodUrl}/ryoko/api/v1/public/trip/${tripId}`, {
                headers: options
            })
            .pipe(
                map(data => {
                    let dataToCache = data;
                    return dataToCache;
                }),
                catchError(this.handleError.bind(this))
            ) as Observable<any>;
    }

}