import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LocalTripMapComponent } from './local-trip-map/local-trip-map.component';
import { PrimaryTripMapComponent } from './primary-trip-map/primary-trip-map.component';
import { UpcountryDealerTrackingViewComponent } from './upcountry-dealer-tracking-view/upcountry-dealer-tracking-view.component';

const routes: Routes = [
  { path: 'invalid-url', component: LandingPageComponent },
  { path: '', redirectTo: 'invalid-url', pathMatch: 'full' },
  { path: 'vehicle-location', component: PrimaryTripMapComponent },
  { path: 'upt/:id', component: UpcountryDealerTrackingViewComponent },
  { path: 'lt/:id', component: LocalTripMapComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
