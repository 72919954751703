<div class="mapDiv">
    <div id="map"></div>
    <div *ngIf="!showLoader" class="running-trip-details">
        <div *ngIf="runningStatus['lastLocation']" class="vehicle-status-wrapper">
            <div class="details-wrapper">
                <div class="header-row">
                    <p class="heading">Last Location</p>
                    <p [ngClass]="['trip-status', runningStatus.status]">
                        {{runningStatus.statusKey}}
                    </p>
                </div>
                <div class="crntLocation">
                    <img src="/assets/images/gmaps_currentLocation.svg">
                    <p class="address">{{runningStatus.lastLocation}}</p>
                </div>
                <p class="time">{{runningStatus.lastLocationTime | date : 'dd MMM - hh:mm a'}}</p>
                <div *ngIf="runningStatus['status'] != 'COMPLETED'" class="eta">
                    <p class="label">Distance Covered </p>
                    <p class="kms">{{runningStatus.distanceFromOrigin}} Kms</p>
                </div>
                <div *ngIf="runningStatus['status'] != 'COMPLETED'" class="eta">
                    <p class="label">ETA </p>
                    <p class="kms">{{runningStatus.etaKm}} Kms</p>
                    <p class="sepr">.</p>
                    <p>{{runningStatus.etaTime}}</p>
                </div>
                <button class="ff_button_28_transparent expand_coll_btn"
                    (click)="runningStatus.expandDetails = !runningStatus.expandDetails">
                    <img [ngClass]="runningStatus.expandDetails ?'row-accordion-rot':''"
                        src="/assets/images/accordian-1px.svg">
                    {{!runningStatus.expandDetails ?'More Details':'Less Details'}}
                </button>
            </div>
            <div *ngIf="runningStatus.expandDetails" class="more-details">
                <div *ngFor="let pingPoint of runningStatus['runningPingPoints']; let runningIndex = index;"
                    class="point">
                    <p class="date">{{pingPoint?.istTimeStamp | date : 'dd MMM yy, hh:mm a'}}</p>
                    <p [ngClass]="['status', pingPoint?.status]">
                        {{pingPoint?.info | titlecase}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>