<div class="loaderContent" *ngIf="showLoader">
    <div class="loader"></div>
</div>
<div class="wrapper" *ngIf="!showLoader"  #scrollContainer>
    <h2 class="ff_heading">Delivery Status </h2>
    <div class="trip-details">
         <div class="row-data">
            <div [ngClass]="['tripStatus', tripDetails.status]">{{tripDetails.status}}</div>
            <p class="value" *ngIf="tripCompletion && tripDetails.status == 'COMPLETED'">As of:  {{
                tripCompletion?.createdOn | date: 'dd MMM yyyy | hh:mm aaa'
              }}</p>
            <p class="value" *ngIf="tripInTransit && tripDetails.status == 'ACTIVE'">As of:  {{
                tripInTransit?.createdOn | date: 'dd MMM yyyy | hh:mm aaa'
              }}</p>
            <!-- <p class="ff_flex eta"><img src="/assets/images/eta.svg">ETA - 20th July 2023</p> -->
        </div>
        <div class="row-data">
            <p class="invoice-key">Invoice ID </p>
            <p class="invoice-value">{{tripDetails?.dropPoints[0]?.invoiceNumbers[0]}}</p>
            <p></p>
            <p class="invoice-key">EWB No. </p>
            <p class="invoice-value">{{tripDetails?.dropPoints[0]?.ewayBills[0]?.ewbNo}}</p>
            <p class="ff_flex valid-upto"><img src="/assets/images/eta.svg">Valid Till: {{tripDetails?.dropPoints[0]?.ewayBills[0]?.validUpto | date: 'dd MMM yyyy | hh:mm aaa'}}</p>
        </div>
        <div class="row-data">
            <ul class="timeline" >
                <li class="timeline-item">
                    <div class="timeline-info origin-details">
                        <p class="origin">{{tripDetails?.originWarehouseName}} - <span class="origin-wh-details">{{tripDetails?.originWarehouseAddress}}</span></p>
                        <p class="started-on">Started On: {{(tripCreation?.createdOn | date: 'dd MMM yyyy | hh:mm aaa')}}</p>
                       </div>
                    <div class="timeline-marker">
                        <img src="/assets/images/origin.svg">
                    </div>
                    <div class="timeline-content">
                    </div>
                </li>
                <li class="timeline-item" *ngIf="tripDetails?.fastagVehicle">
                    <div class="timeline-info origin-details">
                        <p class="origin">{{tripDetails?.dropPoints[0]?.destinationWarehouseName}} - 
                        <span class="origin-wh-details">{{tripDetails?.dropPoints[0]?.destinationWarehouseAddress}}</span></p>
                    </div>
                    <div class="timeline-marker">
                        <img src="/assets/images/gmaps_destination.svg">
                    </div>
                    <div class="timeline-content">
                    </div>
                </li>
            </ul>
        </div>
        <div class="row-data tsp-details">
            <p class="key-value ff_flex"><span class="tsp-key"><img src="/assets/images/tsp-company.svg"></span>{{tripDetails?.tspPartnerNameA}}</p>
            <p class="key-value ff_flex"><span class="tsp-key"><img src="/assets/images/truck-data.svg"></span>{{tripDetails?.vehicleNumber}}</p>
            <p class="key-value ff_flex" *ngIf="tripDetails?.dropPoints[0]?.lorryReceipt?.lorryReceiptNumber"><span class="tsp-key">LR No.</span>{{tripDetails?.dropPoints[0]?.lorryReceipt?.lorryReceiptNumber}}</p>
        </div>
    </div>

    <div class="header_tabs ff_flex">
        <ul class="ff_tab">
            <li [class]="showChartView ? 'active' : ''" (click)="toggleView()">
                <img src="/assets/images/details.svg" alt="">
                Details
            </li>
            <li [class]="!showChartView? 'active' : ''" (click)="toggleView()">
                <img src="/assets/images/maps.svg" alt="">
                Map
            </li>
        </ul>
    </div>
    <!-- <p class="latest-updates">Latest Update <span>{{lastKnownLocation?.readerReadTime | date: 'dd MMM yyyy | hh:mm aaa'}}</span></p> -->
    <div class="details" *ngIf="showChartView">   
        <div class="ff_flex_start">
            <img class="details-img" src="/assets/images/last-location.svg" alt="">
            <div class="row-data" >
                <p class="key">Last Known Location</p>
                <p class="value" *ngIf="!lastKnownLocation">-</p>
                <div class="ff_flex" *ngIf="lastKnownLocation && !showTollLocations">
                    <div class="ff_flex-col">
                        <p class="value">{{lastKnownLocation?lastKnownLocation?.tollPlazaName:'-'}}</p>
                        <p class="sub-value">{{lastKnownLocation?.readerReadTime | date: 'dd MMM yyyy | hh:mm aaa'}}</p>
                        <button class="ff_button_28_transparent-btn margin-top-5" (click)="showAllUpdates()">See All Updates 
                            <img class="down-chevron" src="/assets/images/down-chevron.svg">
                        </button>
                    </div>
                </div>
                <div class="margin-top-10" *ngIf="showTollLocations">
                    <ul class="timeline" >
                        <li class="timeline-item toll-booth" *ngFor="let toll of reversedArray;let index = index">
                            <div class="timeline-info  origin-details">
                                <p class="origin " [ngClass]="{'regular-font': index != 0}">{{toll.tollPlazaName}}</p>
                                <p class="started-on">{{(toll?.readerReadTime | date: 'dd MMM yyyy | hh:mm aaa')}}</p>
                            </div>
                            
                            <div class="timeline-marker">
                                <img *ngIf="index != 0" class="time-line-circle" src="/assets/images/blue-cricle.svg">
                                <img *ngIf="index == 0" class="time-line-toll-booth" src="/assets/images/gmap-toll-booth.svg">
                            </div>
                            <div class="timeline-content">
                               
                            </div>
                        </li>

                        <li class="timeline-item toll-booth" *ngIf="tripDetails?.fastagVehicle">
                            <div class="timeline-info  origin-details">
                                <p class="origin regular-font">Dispatched from the Transporter Hub   </p>
                                <p class="started-on"> {{tripInTransit?.createdOn | date: 'dd MMM yyyy | hh:mm aaa'}}</p>
                            </div>
                            
                            <div class="timeline-marker">
                                <img class="time-line-circle" src="/assets/images/blue-cricle.svg">
                            </div>
                            <div class="timeline-content">
                            </div>
                        </li>

                        <li class="timeline-item toll-booth">
                            <div class="timeline-info  origin-details">
                                <p class="origin regular-font">Departed from {{tripDetails?.originWarehouseName}} on  </p>
                                <p class="started-on"> {{(tripCreation?.createdOn | date: 'dd MMM yyyy | hh:mm aaa')}}</p>
                            </div>
                            <div class="timeline-marker">
                                <img class="time-line-circle" src="/assets/images/blue-cricle.svg">
                            </div>
                            <div class="timeline-content">
                                <button class="ff_button_28_transparent-btn margin-top-5" (click)="showAllUpdates()">Less Updates  <img class="up-chevron" src="/assets/images/down-chevron.svg"></button>
                            </div>
                        </li>

                       
                       
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="details" *ngIf="!showChartView && tollDetails.length">
          <div  class="route-map-wrapper">
        <div class="side-panel">
           
            <div class="vehicle-info-panel">
                <app-trip-map [trip] = "tripDetails"  [tollDetails]="tollDetails"></app-trip-map>
            </div>
        </div>
    </div>
    </div>

    <div *ngIf="!showChartView && !tollDetails.length">
        <p class="no-data">No Details Avaliable!</p>
    </div>
</div>