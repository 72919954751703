import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PrimaryTripMapComponent } from './primary-trip-map/primary-trip-map.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { LocalTripMapComponent } from './local-trip-map/local-trip-map.component';
import { UpcountryDealerTrackingViewComponent } from './upcountry-dealer-tracking-view/upcountry-dealer-tracking-view.component';
import { TripMapComponent } from './upcountry-dealer-tracking-view/trip-map/trip-map.component';

@NgModule({
  declarations: [
    AppComponent,
    PrimaryTripMapComponent,
    LandingPageComponent,
    LocalTripMapComponent,
    UpcountryDealerTrackingViewComponent,
    TripMapComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
