import { Component, OnInit } from '@angular/core';
import { PrimaryTripMapService } from './primary-trip-map.service';
import { forkJoin, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
declare var google;

interface RunningStatus {
  runningPingPoints: any;
  expandDetails: boolean;
  distanceFromOrigin: any;
  lastLocation: any;
  lastLocationTime: any;
  etaKm: any;
  etaTime: any;
  status: String;
  statusKey: String;
}

@Component({
  selector: 'app-vehicle-location-map',
  templateUrl: './primary-trip-map.component.html',
  styleUrls: ['./primary-trip-map.component.scss']
})
export class PrimaryTripMapComponent implements OnInit {

  vehicleMarker: any;
  vehicleMarkers: any = [];
  vehiclePulses: any = [];
  runningStatus: RunningStatus;

  mapCenter = { lat: 20.5937, lng: 78.9629 };
  mapCenterZoom = 5;
  map: any;

  showLoader: boolean = true;
  tripId: any = 20;

  constructor(
    private mapService: PrimaryTripMapService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) { 
    this.activatedRoute.queryParams.subscribe(params => {
      this.tripId = params['tripId'];
    });
  }

  ngOnInit(): void {
    if(!this.tripId){
      this.router.navigate(['']);
    }else{
      setTimeout(() => {
        this.renderMap();
      }, 2000);
    }
  }

  getTripDetails() {
    return new Promise(resolve => {
      this.mapService.getTripDetails(this.tripId)
        .pipe(
          catchError((error: any) => {
            resolve(false);
            return throwError(error);
          })
        )
        .subscribe(resp => {
          resolve(resp);
        });
    });
  }

  async renderMap() {
    this.map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: this.mapCenterZoom,
        center: this.mapCenter,
      }
    );
    let map = this.map;
    let trip = await this.getTripDetails();
    let vehicleCoordinates = { lat: trip['lastLocation'].latitude, lng: trip['lastLocation'].longitude };
    let vehiclePulse = new google.maps.Marker({
      position: vehicleCoordinates,
      label: {
        text: ' ',
        className: 'gmap-marker-pulse',
        anchor: new google.maps.Point(5, 5),
      },
      icon: {
        url: 'assets/images/gmaps_blank.svg',
        scaledSize: new google.maps.Size(10, 10)
      },
      zIndex: 4,
      draggable: false,
      map: this.map
    });
    let vehicleMarker = new google.maps.Marker({
      title: 'vehicleMarkerLayer',
      position: vehicleCoordinates,
      icon: {
        url: 'assets/images/gmaps_blank.svg',
        scaledSize: new google.maps.Size(15, 44),
        anchor: new google.maps.Point(7.5, 25),
      },
      label: {
        text: trip['vehicleNumber'],
        className: 'gmap-vehicle-label',
        anchor: new google.maps.Point(5, 5),
      },
      draggable: false,
      optimized: false,
      zIndex: 5,
      map: this.map,
      trip: trip,
    });

    this.vehicleMarkers.push(vehicleMarker);
    this.vehiclePulses.push(vehiclePulse);

    let contentString = 'Last Location : ' + trip['lastLocation']['address'];
    let infowindow = new google.maps.InfoWindow({
      content: contentString,
    });
    vehicleMarker.addListener('mouseover', function () {
      infowindow.open({
        anchor: vehicleMarker,
        map,
        shouldFocus: true,
      });
    });
    vehicleMarker.addListener('mouseout', function () {
      infowindow.close({
        anchor: vehicleMarker,
        map,
        shouldFocus: true,
      });
    });
    let vehMarker = this.vehicleMarkers[0];
    google.maps.event.addListener(this.map, 'idle', function () {
      setTimeout(function () {
        if (vehMarker != null) {
          let marekerTitle = vehMarker['title'];
          let node: any = document.querySelector(`[title="${marekerTitle}"]`);
          if (node) {
            node.style.transform = 'rotate(' + vehMarker['trip']['angle'] + 'deg)';
            node.style.backgroundImage = "url('assets/images/gmaps_truck.svg')";
            node.style.backgroundSize = "contain";
          }
        }
      }, 200);
    });
    this.renderRoute(vehMarker);
  }

  getVehicleRunningStatus(tripId) {
    return new Promise(resolve => {
      let callArray = [];
      callArray.push(this.mapService.getTripVehicleRunningStatus(tripId));
      callArray.push(this.mapService.getTripVehicleWayPoints(tripId));
      forkJoin(callArray)
        .pipe(
          catchError((error: any) => {
            resolve(false);
            return throwError(error);
          })
        )
        .subscribe(resp => {
          let returnObj = {
            runningPingPoints: resp[0] ? resp[0] : [],
            wayPoints: resp[1] ? resp[1] : [],
          }
          resolve(returnObj);
        });
    });
  }

  async renderRoute(vehicleMarker) {
    this.runningStatus = {
      runningPingPoints: [],
      expandDetails: false,
      distanceFromOrigin: undefined,
      lastLocation: undefined,
      lastLocationTime: undefined,
      etaKm: undefined,
      etaTime: undefined,
      status: undefined,
      statusKey: undefined,
    };
    let tripId = vehicleMarker['trip']['id'];
    let vehLocations = await this.getVehicleRunningStatus(tripId);
    let tripData = vehicleMarker['trip'];
    this.runningStatus.runningPingPoints = vehLocations['runningPingPoints'];
    if (tripData) {
      let lastLocation = tripData['lastLocation'];
      let vehAddressArr = lastLocation['address'] ? lastLocation['address'].split(',') : 'No Details Available'.split(',');
      let vehAddress = vehAddressArr.join(', ');
      let totalDistance = parseInt((lastLocation['totalDistance'] / 1000).toFixed(0));
      let distanceCovered = parseInt((lastLocation['distanceFromOrigin'] / 1000).toFixed(0));
      this.runningStatus.lastLocation = vehAddress;
      this.runningStatus.lastLocationTime = lastLocation['lastUpdatedAt'];
      this.runningStatus.etaKm = Math.abs(totalDistance - distanceCovered);
      this.runningStatus.etaTime = lastLocation['timeRemaining'];
      this.runningStatus.distanceFromOrigin = distanceCovered;
      this.runningStatus.status = tripData['status'];
      this.runningStatus.statusKey = tripData['status'] == 'ACTIVE' ? 'In Transit' : 'Completed';
    }
    if (tripData['status'] == 'ACTIVE') {
      if (tripData['isTripDelayed']) {
        this.runningStatus.status = 'DELAYED';
        this.runningStatus.statusKey = 'Delayed';
      }
      if (tripData['isETABreached']) {
        this.runningStatus.status = 'ETA-BREACHED';
        this.runningStatus.statusKey = 'Std. ETA Breached';
      }
    }
    let waypts = [];
    let allUniqueWayPoints: any = vehLocations['wayPoints'].filter((e, i) => vehLocations['wayPoints'].findIndex(a => a['latitude'] === e['latitude']) === i);
    let wayPointsLength = allUniqueWayPoints.length;
    let step = Math.ceil(wayPointsLength / 5);
    for (let i = 0; i < wayPointsLength; i = i + step) {
      const vehicleWayPoint = new google.maps.LatLng(allUniqueWayPoints[i].latitude, allUniqueWayPoints[i].longitude);
      waypts.push({
        location: vehicleWayPoint,
        stopover: true
      });
    }

    let element = vehicleMarker['trip']['lastLocation'];
    let origin = { lat: element.originLatitude, lng: element.originLongitude };
    let destination = { lat: element.destinationLatitude, lng: element.destinationLongitude };
    const originCoordinates = new google.maps.LatLng(element.originLatitude, element.originLongitude);
    const destinationCoordinates = new google.maps.LatLng(element.destinationLatitude, element.destinationLongitude);
    const vehicleCoordinates = new google.maps.LatLng(element.latitude, element.longitude);

    waypts.push({
      location: vehicleCoordinates,
      stopover: true
    });
    let requestOt = {
      origin: originCoordinates,
      destination: vehicleCoordinates,
      waypoints: waypts,
      optimizeWaypoints: true,
      travelMode: 'DRIVING'
    };
    let requestTd = {
      origin: vehicleCoordinates,
      destination: destinationCoordinates,
      travelMode: 'DRIVING'
    };

    let directionsService = new google.maps.DirectionsService();
    let otDirectionsRendererBorder = new google.maps.DirectionsRenderer();
    let otDirectionsRenderer = new google.maps.DirectionsRenderer();
    let tdDirectionsRenderer = new google.maps.DirectionsRenderer();
    let tdDirectionsRendererBorder = new google.maps.DirectionsRenderer();

    otDirectionsRenderer.setMap(this.map);
    otDirectionsRenderer.setOptions({
      polylineOptions: { strokeColor: "#2AC26B", strokeWeight: 2, strokeOpacity: 1 },
      suppressMarkers: true
    });
    otDirectionsRendererBorder.setMap(this.map);
    otDirectionsRendererBorder.setOptions({
      polylineOptions: { strokeColor: "#0E823F", strokeWeight: 4, strokeOpacity: 1 },
      suppressMarkers: true
    });
    tdDirectionsRenderer.setMap(this.map);
    tdDirectionsRenderer.setOptions({
      polylineOptions: { strokeColor: "#06b0ff", strokeWeight: 2, strokeOpacity: 1 },
      suppressMarkers: true
    });
    tdDirectionsRendererBorder.setMap(this.map);
    tdDirectionsRendererBorder.setOptions({
      polylineOptions: { strokeColor: "#1967d2", strokeWeight: 4, strokeOpacity: 1 },
      suppressMarkers: true
    });
    await directionsService.route(requestOt, function (result, status) {
      if (status == 'OK') {
        otDirectionsRendererBorder.setDirections(result);
        otDirectionsRenderer.setDirections(result);
      }
    });
    await directionsService.route(requestTd, function (result, status) {
      if (status == 'OK') {
        tdDirectionsRendererBorder.setDirections(result);
        tdDirectionsRenderer.setDirections(result);
      }
    });
    let originMarker = new google.maps.Marker({
      position: origin,
      label: {
        text: tripData['originCity'],
        className: 'gmap-marker-label',
      },
      icon: {
        url: 'assets/images/gmaps_origin.svg',
        scaledSize: new google.maps.Size(12, 12)
      }
    });
    let destinationMarker = new google.maps.Marker({
      position: destination,
      label: {
        text: tripData['dropPoints'].slice(-1)[0]['destinationCity'],
        className: 'gmap-marker-label',
      },
      icon: {
        url: 'assets/images/gmaps_destination.svg',
        scaledSize: new google.maps.Size(26, 26)
      }
    });
    let markerMap = this.map;
    for (let [pingIndex, pingPoint] of this.runningStatus.runningPingPoints.entries()) {
      pingPoint['istTimeStamp'] = pingPoint['startTime'];
      pingPoint['status'] = pingPoint['isStopped'] == true ? 'STOPPED' : 'RUNNING';
      if (pingIndex == 0) {
        pingPoint['info'] = pingPoint['isStopped'] ? 'Stopped Since ' : 'Running Since ';
      } else {
        pingPoint['info'] = pingPoint['isStopped'] ? 'Stopped For ' : 'Ran For ';
      }
      if (pingPoint['waitTimeHrs'] != 0) pingPoint['info'] = pingPoint['info'].concat(`${pingPoint['waitTimeHrs'].toString()} Hrs `);
      pingPoint['info'] = pingPoint['info'].concat(`${pingPoint['waitTimeMins'].toString()} Mins`);

      if (pingPoint['isStopped']) {
        const pingCoordinates = new google.maps.LatLng(pingPoint.latitude, pingPoint.longitude);
        let pingMarker = new google.maps.Marker({
          title: 'pingMarkerLayer' + pingIndex,
          position: pingCoordinates,
          label: {
            text: ' ',
            className: 'gmap-marker-vl-stop-pulse',
          },
          icon: {
            url: 'assets/images/gmaps_blank.svg',
            scaledSize: new google.maps.Size(6, 6)
          },
          draggable: false,
          optimized: false,
          zIndex: 4,
          map: markerMap,
        });
      }
    }
    originMarker.setMap(this.map);
    destinationMarker.setMap(this.map);
    this.showLoader = false;
  }

}
