<div class="side-panel" #scrollContainer *ngIf="tollDetails.length">
    <div class="mapDiv">
        <div id="map"></div>
    </div>
    <ng-container *ngIf="animationLayerObject.isAnimationApplicable">
        <div class="track-animate-div">
            <ng-container *ngIf="!animationLayerObject.isAnimationPlayMode; else pauseButton">
                <div class="play-icon-div">
                    <img (click)="startAnimateTruckIcon()" class="play-icon" src="/assets/images/play-button.svg">
                    <p class="play-text">Replay Trip</p>
                </div>
            </ng-container>
            <ng-template #pauseButton>
                <img class="play-icon" (click)="stopTruckAnimate()" src="../../../../assets/images/pause-button.svg">
                <div class="speed-text" (click)="updateAnimationSpeed('1X')">
                    1x
                </div>
                <div class="speed-text" (click)="updateAnimationSpeed('2X')">
                    2x
                </div>
                <div class="speed-text" (click)="updateAnimationSpeed('4X')">
                    4x
                </div>
                <div class="speed-text" (click)="updateAnimationSpeed('10X')">
                    10x
                </div>
            </ng-template>
        </div>
    </ng-container>
</div>

